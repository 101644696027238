<template>
  <div class="PaapStartVote imgLoaded">
    <div>
      <h4 class="PSV_title">Introdu datele necesare</h4>
      <div class="PSV_form">
        <div class="form-flex form-adapt-1  ">
          <div class="form-group">
            <b>Data limită</b>
            <div>
              <date-picker v-model="formData.dataLimita" :disabled-date="dateChecker" valueType="YYYY-MM-DDTHH:mm:ssZ" />
            </div>
          </div>
          <div class="form-group">
            <b>Număr minim de voturi ce pot fi exprimate</b>
            <inp-number :stringMode="true" v-model="formData.numarMinimVoturiCeTrebuieExprimate" />
          </div>
        </div>
        <div class="form-flex form-adapt-1  ">
          <div class="form-group">
            <b>Număr statutar de voturi ce pot fi exprimate</b>
            <inp-number :disabled="!formData.numarMinimVoturiCeTrebuieExprimate" :stringMode="true" v-model="formData.numarVoturiSpreExprimate" />
            <small v-if="isValidMinim[0]" style="color: red;">Indicați o valoare mai mare sau egală cu „Număr minim de voturi ce pot fi exprimate”.</small>
          </div>
          <div class="form-group">
            <b>Număr minim de voturi de aprobare</b>
            <inp-number :disabled="!formData.numarMinimVoturiCeTrebuieExprimate" :stringMode="true" v-model="formData.numarMinimVoturiDeAprobare" />
          </div>
        </div>
      </div>
      <h4 class="PSV_title" style="margin-top: 2rem;">
        Alege participanții procesului de vot
      </h4>
      <div class="PSV_form">
        <div class="form-group">
          <b>Alege participanții</b>
          <v-select
            :options="consiliers"
            :disabled="!formData.numarMinimVoturiCeTrebuieExprimate"
            :multiple="true"
            v-model="selectedConsiliers"
          >
            <template #option="{ label }">
              <span class="v-select_li_custom">
                <span>{{ label }}</span>
                <i v-tooltip="label" class="fas fa-info-circle"></i>
              </span>
            </template>
          </v-select>
        </div>
        <div @click="startVoteSession()" class="Button1" style="min-width: 12rem;">
          <div class="icon">
            <img
              @load="imgLoaded"
              src="../assets/media/for_table/circle_ADD_new_etap.svg"
              alt=""
            />
          </div>
          <span class="Btn_content">
            Start
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PAAP_VOTE } from '@/api.js'
import DatePicker from '@/mixins/DatePicker.js'

const formData = () => ({
  dataLimita: null,
  numarVoturiSpreExprimate: null,
  numarMinimVoturiCeTrebuieExprimate: null,
  numarMinimVoturiDeAprobare: null,
})

export default {
  mixins: [DatePicker],
  props: {
    referenceId: {
      type: Number,
      required: true,
      default: 0
    },
    closeModal: {
      type: Function,
      required: true,
      default: ()=>{}
    }
  },
  computed: {
    isValidMinim() {
      const x = this.formData
      const y = val => val ? +val : 0

      return [
        y(x.numarMinimVoturiCeTrebuieExprimate) > y(x.numarMinimVoturiCeTrebuieExprimate)
      ]
    }
  },
  data() {
    return {
      formData: formData(),
      consiliers: [],
      selectedConsiliers: []
    }
  },
  methods: {
    startVoteSession() {
      const x = this.formData
      if(this.objValidator(x)) {
        if(this.selectedConsiliers.length) {
          const error = msg => {
            this.setLoad()
            this.closeModal()
            this.$toastr.e(msg || 'Inițierea procesului de vot a eșuat.')
          }

          this.setSafeLoad()
          PAAP_VOTE.startVote({
            dataLimita: new Date(x.dataLimita).toISOString(),
            numarVoturiSpreExprimate: +x.numarVoturiSpreExprimate,
            numarMinimVoturiCeTrebuieExprimate: +x.numarMinimVoturiCeTrebuieExprimate,
            numarMinimVoturiDeAprobare: +x.numarMinimVoturiDeAprobare,
            needReferate: +this.referenceId,
            participants: this.selectedConsiliers.map(e => ({
              userRole: e.id
            }))
          }).then(res => {
            if(this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
              this.setLoad()
              this.closeModal()
              this.$toastr.s('Procesul de vot a fost inițiat cu succes.')
            } else {
              error()
            }
          }).catch(error)
        } else {
          this.$toastr.w('Selectați măcar un participant.')
        }
      } else {
        this.$toastr.w('Completați toate câmpurile obligatorii.')
      }
    },
    loadConsiliers() {
      const error = msg => {
        this.$toastr.e(msg || 'Încărcarea listei de consilieri a eșuat.')
      }

      PAAP_VOTE.getConsiliers(this.getUserInstitution(true)?.id ?? null).then(res => {
        if(Array.isArray(res?.data?.result)) {
          this.consiliers = res.data.result.map(e => ({
            id: e.id,
            label: `${this.readUserObject(e.user)} (${this.changeRoleView(e.role)})`,
            data: e
          }))
        } else {
          error()
        }
      }).catch(error)
    }
  },
  created() {
    this.loadConsiliers()
  }
}
</script>

<style src="@/assets/PaapStartVote.css"></style>
