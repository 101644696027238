<template>
  <div class="PaapComments">
    <comments
      v-model="topicIdSidebar"
      :paapId="paapId"
      :referenceId="referenceId"
      :getDefaultAuthorName="getDefaultAuthorName"
    />
    <div class="PC_head">
      <div>Topicuri & Comentarii ({{ totalRecords }})</div>
      <div>
        <div @click="addTopic" class="Button1">
          <div class="icon">
            <img
              @load="imgLoaded"
              src="@/assets/media/for_table/circle_ADD_new_etap.svg"
              alt=""
            />
          </div>
          <span class="Btn_content">Adaugă topic</span>
        </div>
      </div>
    </div>
    <div v-if="apiState" style="text-align: center;">
      <div v-if="apiState > 1">
        <h4 class="prettyH4" style="color: #888">
          <i class="fas fa-exclamation-triangle"></i>
          Nu sunt topicuri
        </h4>
        <a v-if="apiState == 3" @click.prevent="syncData()" href="#"
          >Încearcă din nou</a
        >
      </div>
      <span v-else style="font-weight: bold;font-size: 2rem;">
        Se încarcă...
        <i class="fas fa-sync fa-spin"></i>
      </span>
    </div>
    <template v-else>
      <div ref="PC_box" class="PC_box">
        <div v-for="e in prepared" :key="e.id" class="PC_Comm">
          <div class="PC_Comm_Message">
            <div class="PC_Comm_Message_Content">
              <p>{{ e.topicName }}</p>
              <i>
                <small>
                  Creat de {{ e.author }} la data de {{ e.createdAt }}
                </small>
              </i>
            </div>
            <div class="PC_Comm_Message_Btn">
              <a @click.prevent.stop="viewComments(e.id)" href="#"
                >Vezi comentarii</a
              >
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 1rem;text-align: right;">
        <pagination
          v-model="currPage"
          :records="totalRecords"
          :options="paginationOptions"
        />
      </div>
    </template>
  </div>
</template>

<style src="@/assets/PaapComments.css"></style>

<script>
import { PAAP } from '@/api.js'
import Pagination from 'vue-pagination-2'
import DialogModal from '@/components/DialogModal'
import Comments from './Sidebar'

export default {
  props: {
    paapId: {
      type: Number,
      required: true,
      default: 0,
    },
    referenceId: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  components: {
    Comments,
    Pagination,
  },
  data() {
    return {
      apiState: false,
      topicIdSidebar: false,
      topics: {},
      currPage: 1,
      chunksSize: 25,
      totalRecords: 0,
      modalElement: null,
      paginationOptions: {
        texts: {
          count: '',
        },
      },
      getDefaultAuthorName() {
        return this.getUserName()
      }
    }
  },
  computed: {
    isValid() {
      return !!this.paapId && !!this.referenceId
    },
    prepared() {
      const x = this.topics[this.currPage]
      return Array.isArray(x) ? x : []
    },
  },
  watch: {
    currPage(page) {
      this.syncData(page)
      this.scrollBoxToTop()
    },
  },
  methods: {
    scrollBoxToTop() {
      const el = this.$refs['PC_box']
      const modal = this.modalElement
      if (el) {
        el.scrollTop = 0
      }
      if (modal) {
        window.requestAnimationFrame(
          () => (modal.scrollTop = modal.scrollHeight)
        )
      }
    },
    readApiData(data) {
      if (!Array.isArray(data)) return []

      return data
        .map(e => ({
          topicName: e.topicName || '-',
          author: e.author || '-',
          // username: this.readUserObject(e.createdByUser),
          createdAt: e.createdAt ? this.toDate(e.createdAt) : '-',
          id: e.id ?? this.makeid(6),
          isValid: !!e.id,
        }))
        .filter(e => e.isValid)
    },
    resetPagination(doNotSync) {
      this.topics = {}
      this.currPage = 1
      this.totalRecords = 0
      if (!doNotSync) this.syncData()
    },
    syncData(page = 1, forced) {
      if (!page > 0 || !this.isValid || (!forced && this.topics[this.currPage]))
        return
      if (forced) {
        this.topics = {}
      }

      const error = msg => {
        this.$toastr.e(msg || 'Topicurile nu au fost încărcate!')
        this.apiState = 3

        this.resetPagination(true)
      }

      this.apiState = true
      PAAP.getTopics(this.paapId, this.referenceId, page, this.chunksSize)
        .then(res => {
          if (Number.isInteger(res?.data?.recordsQuantity)) {
            this.totalRecords = res.data.recordsQuantity
          }
          if (Array.isArray(res?.data?.result)) {
            if (res.data.result.length) {
              this.$set(this.topics, page, this.readApiData(res.data.result))

              if (page !== this.currPage) {
                this.currPage = page
              }
              this.apiState = false
              if (page !== 1) this.scrollBoxToTop()
            } else {
              if (this.currPage > 1) {
                this.$toastr.w(`Pagina ${this.currPage} nu a fost găsită.`)
              }

              this.resetPagination(true)
              this.apiState = 2
            }
          } else {
            error()
          }
        })
        .catch(error)
    },
    addTopic() {
      this.$modal.show(
        DialogModal,
        {
          target: 'AddTopic',
          title: 'Adaugă topic',
          inputs: [
            {
              label: 'Nume autor',
              id: 'author',
              value: this.getDefaultAuthorName(),
              errReason: 'Introduceți numele autorului.',
            },
            {
              label: 'Denumire topic',
              id: 'topicName',
              errReason: 'Introduceți denumirea topicului.',
            },
          ],
          closeBtnText: 'Anulează',
          button: {
            type: 2,
            value: 'Adaugă',
            handler: ({ topicName, author }) => {
              const error = msg => {
                this.$toastr.e(msg || 'Crearea topicului a eșuat.')
                this.setLoad()
              }

              this.setSafeLoad(12000)
              PAAP.addTopic({
                paap: this.paapId,
                referat: this.referenceId,
                topicName,
                author,
              })
                .then(res => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.syncData(1, true)
                    this.$toastr.s('Topicul a fost creat cu succes.', 'Succces')
                    this.setLoad()
                  } else {
                    error()
                  }
                })
                .catch(error)
            },
          },
        },
        {
          name: 'AddTopic',
          adaptive: true,
          width: '650',
          height: '800',
        }
      )
    },
    viewComments(topicId) {
      if (!Number.isInteger(topicId)) return

      this.topicIdSidebar = false
      this.$nextTick(() => (this.topicIdSidebar = topicId))
    },
  },
  created() {
    this.syncData()
  },
  mounted() {
    this.modalElement = document.getElementById('HJX54kJaTy4bGKMU')
  },
}
</script>
