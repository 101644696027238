const elementResizeDetectorMaker = require("element-resize-detector")
const erdUltraFast = elementResizeDetectorMaker({
  strategy: "scroll",
})

export default {
  data() {
    return {
      ACheights: {},
      ACS: [],
    }
  },
  computed: {
    ACHcomputed() {
      const x = this.selected
      const prepare = {}
      Object.entries(this.ACheights)?.forEach((e) => {
        const y = parseInt(e[0].charAt(2)) - 1
        prepare[e[0]] =
          x && x[y] ? `max-height:${e[1]}px !important;overflow: hidden;` : ""
      })
      return prepare
    },
  },
  methods: {
    syncACS() {
      this.ACS = Object.entries(this.$refs)
        .filter((e) => e[0].startsWith("AC"))
        .map((e) => (Array.isArray(e[1]) ? [e[0], e[1][0]] : e))
    },
    heightsInit() {
      const prepare = {}
      this.ACS?.forEach((e) => {
        prepare[e[0]] = Math.max(
          e[1].offsetHeight + (e[1].offsetHeight / 100) * 4 + 30,
          e[1].offsetHeight
        )
      })
      this.ACheights = prepare
    },
    initListeners() {
      this.ACS?.forEach((e) => {
        erdUltraFast.listenTo(e[1], this.heightsInit)
      })
    },
    clearListeners() {
      this.ACS?.forEach((e) => {
        erdUltraFast.removeListener(e[1], this.heightsInit)
      })
    },
    resetLAcordion() {
      this.clearListeners()

      this.$nextTick(() => {
        this.syncACS()
        this.heightsInit()
        this.initListeners()
      })
    },
  },
  beforeDestroy() {
    this.clearListeners()
  },
  mounted() {
    this.resetLAcordion()
  },
}
