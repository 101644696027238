<template>
  <div class="PaapViewerParent">
    <div style="display: flex;gap: 1rem;margin-bottom: 1.75rem;">
      <statistics-box class="solid-blue no-clickable">
        <i class="far fa-sack-dollar" slot="icon"></i>
        {{ totalEstimatedValue }}
        <span slot="label-right">Total estimat</span>
      </statistics-box>
      <statistics-box class="solid-blue no-clickable">
        <i class="far fa-container-storage" slot="icon"></i>
        {{ totalRecords }}
        <span slot="label-right">Nr. referate</span>
      </statistics-box>
    </div>
    <div class="LAccordionParent nqowjkdnihvciax LAccordion-StickyStyle">
   <!--    <div class="LAccordionItem" :class="selected[0] ? 'LActive' : ''">
        <div @click="selectAccordion(0)" class="LAccordionItemHead">
          <span
            >Detalii identificare <i class="fas fa-angle-down rotateToTop"></i
          ></span>
          <div>
            <i class="fas fa-times"></i>
            <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
          </div>
        </div>
        <div class="LAccordionItemBody" :style="ACHcomputed['AC1']">
          <div ref="AC1">
            <div
              class="widget-body form-group-light"
              style="min-height: 50px; background-color: # !important"
            >
              <div class="form-flex">
                <div class="form-group">
                  <b>Cod unic PAAP</b>
                  <div v-tooltip="paapData.codUnic">
                    <input
                      type="text"
                      class="vInput form-disabled"
                      :value="paapData.codUnic"
                      disabled=""
                    />
                  </div>
                </div>
                <div class="form-group">
                  <b>Denumire plan</b>
                  <div v-tooltip="paapData.denumirePlan">
                    <input
                      type="text"
                      class="vInput form-disabled"
                      :value="paapData.denumirePlan"
                      disabled=""
                    />
                  </div>
                </div>
                <div class="form-group">
                  <b>Cod plan</b>
                  <div v-tooltip="paapData.codPlan">
                    <input
                      type="text"
                      class="vInput form-disabled"
                      :value="paapData.codPlan"
                      disabled=""
                    />
                  </div>
                </div>
                <div class="form-group">
                  <b>An</b>
                  <div v-tooltip="paapData.an">
                    <input
                      type="text"
                      class="vInput form-disabled"
                      :value="paapData.an"
                      disabled=""
                    />
                  </div>
                </div>
              </div>
              <div class="form-flex">
                <div class="form-group">
                  <b>Versiune</b>
                  <div v-tooltip="paapData.versiune">
                    <input
                      type="text"
                      class="vInput form-disabled"
                      :value="paapData.versiune"
                      disabled=""
                    />
                  </div>
                </div>
                <div class="form-group">
                  <b>Stare plan</b>
                  <div v-tooltip="paapData.starePlan">
                    <input
                      type="text"
                      class="vInput form-disabled"
                      :value="paapData.starePlan"
                      disabled=""
                    />
                  </div>
                </div>
                <div class="form-group">
                  <b>Data publicare</b>
                  <div v-tooltip="paapData.dataPublicare">
                    <input
                      type="text"
                      class="vInput form-disabled"
                      :value="paapData.dataPublicare"
                      disabled=""
                    />
                  </div>
                </div>
                <div class="form-group">
                  <b
                    >CIF
                    <i
                      class="fas fa-info-circle"
                      v-tooltip="'CIF si denumire autoritate contractanta'"
                    ></i
                  ></b>
                  <div v-tooltip="paapData.cif">
                    <input
                      type="text"
                      class="vInput form-disabled"
                      :value="paapData.cif"
                      disabled=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="LAccordionItem" :class="selected[1] ? 'LActive' : ''">
        <div @click="selectAccordion(1)" class="LAccordionItemHead">
          <span>Filtre <i class="fas fa-angle-down rotateToTop"></i></span>
          <div>
            <i class="fas fa-times"></i>
            <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
          </div>
          <span @click.stop="" id="nvqoiokqndapo"></span>
        </div>
        <div class="LAccordionItemBody" :style="ACHcomputed['AC2']">
          <div ref="AC2">
            <PaapViewerFilter :paap="paap" @filter="filter" />
          </div>
        </div>
      </div>
    </div>
    <div class="panel-results">
      <h4 class="total-paap_result" style="padding: 0;">
        {{ references.length }} Rezultat{{ references.length === 1 ? "" : "e" }}
      </h4>
      <div class="paap-preview_type">
        <span>
          Tip afișare:
        </span>
        <div>
          <div
            @click="changeViewType(false)"
            :class="{ active: !viewModeTable }"
          >
            <i class="fas fa-bars"></i>
          </div>
          <div @click="changeViewType(true)" :class="{ active: viewModeTable }">
            <i class="fas fa-table"></i>
          </div>
        </div>
      </div>
    </div>
    <div ref="referencesPreview" class="referencesPreview">
      <table-component
        v-if="viewModeTable"
        :key="referencesTableKey"
        :Data="referencesData"
        @Btn14="viewPdf"
        @Btn3="deleteNr"
        @mounted="scrollPreview"
      >
        <!--  <template v-slot:modal-slot="{ row, closeModal, actionBtn }">
          <div class="ModalFlex hideBottom" style="background-color: #dde5ed;">
            <div
              v-if="actionBtn == 7"
              id="HJX54kJaTy4bGKMU"
              class="Popup_Inner_main"
              style="background-color: #dde5ed;"
            >
              <div @click="closeModal" class="Popup_close_btn">
                <img
                  @load="imgLoaded"
                  class="imgHide"
                  src="../assets/media/vectors/close.svg"
                  alt=""
                />
              </div>
              <div class="Popup_title">
                Previzualizare referat
              </div>
              <PaapVoteButtons
                :voteData="referencesVoteData[row.id]"
                :closeModal="
                  () => {
                    closeModal();
                    insertDataInTable(filtered, true);
                  }
                "
              />
              <div style="text-align: left;">
                <PaapReferencePreview
                  style="background-color: #dde5ed;"
                  :paap="paap"
                  :boxColor="true"
                  :reference="row"
                  :usersAvatarCache="usersAvatarCache"
                />
                <PaapVoteStatistics
                  v-if="referencesVoteData[row.id]"
                  :voteData="referencesVoteData[row.id]"
                />
                <Comments :paapId="paap.id" :referenceId="row.id" />
              </div>
            </div>
            <div
              v-else-if="actionBtn == 29"
              id="HJX54kJaXy4bGKMU"
              class="Popup_Inner_main"
              style="background-color: #dde5ed;"
            >
              <div @click="closeModal" class="Popup_close_btn">
                <img
                  @load="imgLoaded"
                  class="imgHide"
                  src="../assets/media/vectors/close.svg"
                  alt=""
                />
              </div>
              <div class="Popup_title">
                Lansează sesiune de vot
              </div>
              <div style="text-align: left;">
                <PaapStartVote
                  :referenceId="row.id"
                  :closeModal="
                    () => {
                      closeModal();
                      insertDataInTable(filtered, true);
                    }
                  "
                />
              </div>
            </div>
          </div>
        </template> -->
      </table-component>
      <template v-else-if="references.length" v-for="e in references">
        <div
          :key="e.id"
          style="border-left: 4px solid #347bb7;padding-left: 1rem;"
        >
          <h4 style="color: #0276c8;">{{ e.name }}</h4>
          <PaapReferencePreview
            :paap="paap"
            :reference="e"
            :usersAvatarCache="usersAvatarCache"
          />
        </div>
      </template>
      <div v-else style="margin-bottom: 10rem;">
        <h4 class="prettyH4" style="color: #888">
          <i class="fas fa-exclamation-triangle"></i>
          Nu sunt referate
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import { PAAP_VOTE, REFERENCE_DELETE_PAAP } from "@/api.js";
import PermisionsMixin from "@/mixins/paapPermMixin.js";
import LAcordion from "@/mixins/LAcordion.js";
import PaapViewerFilter from "@/components/PaapViewerFilter";
import PaapReferencePreview from "@/components/PaapReferencePreview";
import PaapStartVote from "@/components/PaapStartVote";
import TableComponent from "@/components/Table";
import VerticalTable from "@/components/VerticalTable";
import PaapVoteButtons from "@/components/PaapVoteButtons";
import PaapVoteStatistics from "@/components/PaapVoteStatistics";
import Comments from "@/components/PaapComments";
import DialogModal from "@/components/DialogModal";
import { mapMutations } from "vuex";
import PaapCentralization from "../views/PaapCentralization.vue";

const viewModeStorageKey = "paapPanelViewMode";

const tableData = () => ({
  options: {
    specialActions: {
      "7": "showModalSlot",
      "29": ["showModalSlot", "3"],
    },
  },
  head: [
    {
      title: "NR.",
      minimize: 2,
      sort: true,
    },
    {
      title: "Denumire referat",
    },
    {
      title: "Cod CPV (dominant)",
    },
    {
      title: "Valoarea estimată totală, fără TVA",
    },
    {
      spacer: true,
      minimize: 1,
    },
  ],
  body: [],
});

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default {
  mixins: [PermisionsMixin, LAcordion],
  components: {
    PaapViewerFilter,
    PaapReferencePreview,
    PaapStartVote,
    TableComponent,
    VerticalTable,
    PaapVoteButtons,
    PaapVoteStatistics,
    Comments,
  },
  props: {
    paap: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    const getPaapUnique = (paap, reference) => {
      if (!this.isObject(paap) || !this.isObject(reference)) return "";
      const fixZero = (id) => {
        id = +id;
        if (isNaN(id)) return "9999990";
        let prepare = String(id);
        while (prepare.length < 7) {
          prepare = "0" + prepare;
        }
        return prepare;
      };
      return `${paap.codUnicIdentificareContract || "(err)"}_${paap.paapyear ||
        ""}_PPAPD${fixZero(reference.id)}`;
    };

    if (Array.isArray(this.paap.needReferate)) {
      this.paap.needReferate = this.paap.needReferate.reverse();

      for (let e of this.paap.needReferate) {
        e._codUnic = getPaapUnique(this.paap, e);
        e._observatii = `Cod unic de identificare conform art.8, alin.1 din OUG 98/2017 = ${e._codUnic}`;
      }
    }

    return {
      selected: {
        0: false,
        1: false,
      },
      referencesData: tableData(),
      referencesTableKey: 0,
      createdAt: null,
      viewModeTable: true,
      filtered: [...(this.paap.needReferate || [])],
      usersAvatarCache: {},
      referencesVoteData: {},
    };
  },
  watch: {
    viewModeTable(x) {
      this.scrollPreview();
      this.syncStorage();
      if (x) {
        this.insertDataInTable(this.filtered, true);
      }
    },
    filtered(data) {
      if (this.viewModeTable) {
        this.insertDataInTable(data, true);
      }
    },
  },
  computed: {
    totalRecords() {
      return this.paap.needReferate?.filter((e) => e).length ?? 0;
    },
    totalEstimatedValue() {
      return this.prepareLocaleNumber(
        this._Sum(this.paap.needReferate?.map((e) => e.totalValueWithoutTva))
      );
    },
    references() {
      const x = this.filtered;
      const institution = this.paap?.institution || null;

      return Array.isArray(x)
        ? x.map((e) => ({
            ...e,
            id: e.id ?? this.makeid(8),
            institution,
          }))
        : [];
    },
    paapData() {
      const paap = this.paap;

      const version =
        paap.label ||
        (Number.isInteger(paap.version?.versionNumber)
          ? `V${paap.version.versionNumber.toFixed(2)}`
          : "-");

      return {
        codUnic: "",
        denumirePlan: paap.name || "",
        codPlan: "",
        an: paap.paapyear ? String(paap.paapyear) : "",
        versiune: version,
        starePlan:
          {
            asteptare_aprobare: "Așteptare aprobare",
            approved: "Aprobat",
            rejected: "Respins",
          }[paap.paapStatus] ||
          paap.paapStatus ||
          "",
        dataPublicare: this.toDate(paap.paapPublishingDate),
        cif: paap.institution?.cif || "",
      };
    },
  },
  methods: {
    ...mapMutations(["setReferenceIdForPreview"]),
    viewPdf(index) {
      this.setReferenceIdForPreview({
        data: {
          id: this.referencesData.body[index]._Data.id,
        },
        origin: "REFERENCE_GET_PDF",
      });
    },
    scrollPreview() {
      if (Date.now() - this.createdAt < 1990) {
        return;
      }

      const findEl = document.querySelector(".panel-results");
      setTimeout(
        this.scrollElToTOp,
        199,
        findEl || this.$refs["referencesPreview"]
      );
    },
    selectAccordion(index) {
      if (!Number.isInteger(index)) return;
      this.selected[index] = !this.selected[index];
    },
    cancelPaapVote(index) {
      const find = this.referencesVoteData[this.references[index]?.id];
      if (!Number.isInteger(find?.id)) return;

      this.$modal.show(
        DialogModal,
        {
          target: "VoteDelete",
          title: `Șterge proces de vot`,
          content:
            '<span style="color:#f44336;display: block;margin-top: 2rem;"><i class="fas fa-exclamation-circle" style="font-size: 6rem;"></i><p style="font-weight: 500;font-size: 2.2rem;">Toate datale statistice vor fi șterse.</p></span>',
          closeBtnText: "Anulează",
          button: {
            type: 3,
            value: "Șterge",
            handler: () => {
              const error = (msg) => {
                this.setLoad();
                this.$toastr.e(msg || "Ștergerea procesului de vot a eșuat.");
              };

              this.setSafeLoad();
              PAAP_VOTE.deleteVote(find.id)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.setLoad();
                    this.$toastr.s("Procesul de vot a fost șters cu succes.");
                    this.insertDataInTable(this.filtered, true);
                    delete this.referencesVoteData[this.references[index]?.id];
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "VoteDelete",
          adaptive: true,
          width: "650",
          height: "400",
        }
      );
    },
    deleteNr(index) {
      const error = (msg) => {
        this.$toastr.e(msg || "Ștergerea referatului a eșuat.");
        this.setLoad(false);
        this.$emit("refresh");
      };
      this.setLoad(true);

      REFERENCE_DELETE_PAAP({
        id: this.referencesData.body[index]._Data.id,
        paap: this.$route.params.id,
        centralizationId: this.$route.params.centralizationId,
      })
        .then((res) => {
          if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            if (this.referencesData.body.length < 2) {
              const paapId = this.$route.params.id;
              this.safePushRouter(
                `/${this.$route.params.navigation}/paap/${paapId}/centralizare`
              );
            }

            this.$router.go();
            this.$toastr.s("PAAP a fost șters cu succes.");
            this.referencesTableKey++;
            this.setLoad(false);
          } else {
            error();
          }
        })
        .catch(error);
    },
    prepareReferencesForTable(data, showEmpty) {
      data = data || this.filtered;
      if (!Array.isArray(data)) return [false];
      const institution = this.paap?.institution || null;

      const filtered = data.map((e, index) => {
        let need;

        if (e.complexIncludedNeeds && e.complexIncludedNeeds.length > 0) {
          need = e.need.dominantNeed;
        } else {
          need = e.need;
        }

        const buttons = [14];

        if (
          this.paap.approveVersion == 2 &&
          ["draft", "rejected"].includes(this.paap.paapStatus)
        ) {
          buttons.push([3, "Șterge"]);
        }

        return Object.assign(
          [
            {
              val: index + 1,
              border: "right",
            },
            e.name ?? "-",
            need?.product?.cpvCategory?.cpvCode ?? "-",
            this.prepareLocaleNumber(e.totalValueWithoutTva),
            [buttons],
          ],
          {
            index,
            NR: index,
            _Data: {
              ...e,
              institution,
            },
          }
        );
      });

      return showEmpty && !filtered.length ? [false] : filtered;
    },
    insertDataInTable(references, isFiltering) {
      const data = this.prepareReferencesForTable(
        references || null,
        !!isFiltering
      );
      const setData = (x) => (this.referencesData.body = x);
      const promises = [];

      this.references.forEach((e) =>
        promises.push(PAAP_VOTE.getVote(e.id).then((r) => [r, e.id]))
      );

      Promise.all(promises).then((results) => {
        results.forEach((res) => {
          const findRow = data.find((r) => r._Data?.id === res[1]);
          if (!Array.isArray(findRow)) return;
          const addButton = (btn) => findRow.at(-1)[0].splice(1, 0, btn);

          if (
            Array.isArray(res[0]?.data?.result) &&
            res[0].data.result[1] > 0
          ) {
            const responseData = res[0].data.result[0][0];

            this.referencesVoteData[res[1]] = responseData;

            findRow[findRow.length - 2] = capitalizeFirstLetter(
              new Date() > new Date(responseData.dataLimita)
                ? "expirat"
                : responseData.stareaSesiuniiDeVotReferat
            );

            /*    if (this.PERMISIONS.cancelVote) {
              addButton([
                31,
                "Șterge sesiune de vot",
                ["fad fa-trash", "font-size:2.2rem;color:red;"],
              ]);
            } */
          } /* else if (this.PERMISIONS.startVote) {
            addButton([
              29,
              "Lansează sesiune de vot",
              ["fas fa-vote-nay", "font-size:2.2rem;color:#225a93;"],
            ]);
          } */
        });
        if (!isFiltering) setData(data);
        else {
          setData([]);
          this.referencesTableKey++;

          setTimeout(setData, 250, data);
        }
      });
    },
    filter(references) {
      if (!Array.isArray(references)) return;

      if (this.viewModeTable) {
        this.filtered = references;
      } else {
        this.simulateLoad(() => (this.filtered = references));
      }
    },
    syncStorage(readStorage) {
      if (readStorage) {
        this.viewModeTable =
          {
            extended: false,
            table: true,
          }[localStorage.getItem(viewModeStorageKey)] ?? true;
      } else {
        localStorage.setItem(
          viewModeStorageKey,
          {
            false: "extended",
            true: "table",
          }[this.viewModeTable]
        );
      }
    },
    changeViewType(type) {
      this.viewModeTable = !!type;
    },
  },
  created() {
    this.createdAt = Date.now();

    this.syncStorage(true);
    this.insertDataInTable();
  },
};
</script>

<style src="@/assets/LAcordion.css"></style>
<style src="@/assets/PaapViewer.css"></style>
