<template>
  <div class="vote-results">
    <clasic-modal v-model="detailesModal" extendedMode="1">
      <div class="ModalFlex">
        <div class="Popup_Inner_main">
          <div @click="setModal()" class="Popup_close_btn">
            <img
              @load="imgLoaded"
              class="imgHide"
              src="@/assets/media/vectors/close.svg"
              alt=""
            />
          </div>
          <div class="Popup_title">Detalii vot</div>
          <div class="TBParent">
            <table>
              <thead>
                <th>Utilizator</th>
                <th>Rol</th>
                <th>Data exprimării votului</th>
                <th>Vot</th>
              </thead>
              <tbody>
                <tr v-if="!detailesModalData || !detailesModalData.length">
                  <td colspan="4" style="background-color: #fff;">
                    <h4 class="prettyH4">
                      <i class="fas fa-exclamation-triangle"></i>
                      Nu sunt voturi
                    </h4>
                  </td>
                </tr>
                <tr v-else v-for="(e, i) in detailesModalData" :key="i">
                  <td>{{ e.username }}</td>
                  <td>{{ e.role }}</td>
                  <td>{{ e.date }}</td>
                  <td>{{ e.vote }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </clasic-modal>
    <div class="title">Rezultate vot:</div>
    <div class="vote-boxes">
      <div style="flex: 1;">
        <div class="vote-boxes-title">Voturi exprimate</div>
        <div class="vote-boxes-inner first">
          <div class="box max">
            <statistics-box class="solid-blue double-height" @click="showVoteDetails('totalVotted')">
              <i class="far fa-vote-yea" slot="icon"></i>
              {{ statistics.totalVotted }}
              <span slot="label-right">Total</span>
            </statistics-box>
          </div>
          <div class="box">
            <statistics-box class="solid-green" @click="showVoteDetails('approved')">
              <i class="far fa-check-square" slot="icon"></i>
              {{ statistics.approved }}
              <span slot="label-right">De aprobare</span>
            </statistics-box>
          </div>
          <div class="box">
            <statistics-box class="solid-red" @click="showVoteDetails('rejected')">
              <i class="far fa-times-square" slot="icon"></i>
              {{ statistics.rejected }}
              <span slot="label-right">De respingere</span>
            </statistics-box>
          </div>
          <div class="box">
            <statistics-box class="solid-white" @click="showVoteDetails('neutral')">
              <i class="far fa-meh" slot="icon"></i>
              {{ statistics.neutral }}
              <span slot="label-right">De abținere</span>
            </statistics-box>
          </div>
        </div>
      </div>
      <div>
        <div class="vote-boxes-title">Voturi neexprimate</div>
        <div class="vote-boxes-inner">
          <div class="box max">
            <statistics-box class="solid-blue double-height" @click="showVoteDetails('totalUnvotted')">
              <i class="far fa-vote-nay" slot="icon"></i>
              {{ statistics.totalUnvotted }}
              <span slot="label-right">Total</span>
            </statistics-box>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const getDefaultStatisticsData = () => ({
  totalVotted: 0,
  approved: 0,
  neutral: 0,
  rejected: 0,
  totalUnvotted: 0,
})

export default {
  props: {
    voteData: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      statistics: getDefaultStatisticsData(),
      detailesModal: false,
      detailesModalData: null,
    }
  },
  methods: {
    setModal(data) {
      if (data) {
        this.detailesModalData = data
        this.detailesModal = true
      } else {
        this.detailesModal = false
        this.detailesModalData = null
      }
    },
    syncStatistics() {
      if (!Array.isArray(this.voteData?.participants)) return
      const prepare = getDefaultStatisticsData()

      for (let participant of this.voteData.participants) {
        const x = participant.optiuneaVotului
        if (x) {
          prepare['totalVotted']++
          const key = {
            aprob: 'approved',
            resping: 'rejected',
            'ma abtin': 'neutral',
          }[x]

          if (key) {
            prepare[key]++
          }
        } else {
          prepare['totalUnvotted']++
        }
      }

      this.statistics = prepare
    },
    showVoteDetails(voteKey) {
      if (!Array.isArray(this.voteData?.participants)) return
      let prepare = []
      const key = {
        approved: 'aprob',
        rejected: 'resping',
        neutral: 'ma abtin',
      }[voteKey]

      if (key) {
        prepare = this.voteData.participants.filter(
          e => e.optiuneaVotului === key
        )
      } else {
        if (voteKey == 'totalVotted') {
          prepare = this.voteData.participants.filter(e => e.optiuneaVotului)
        } else if (voteKey == 'totalUnvotted') {
          prepare = this.voteData.participants.filter(e => !e.optiuneaVotului)
        }
      }

      this.setModal(
        prepare.map(e => ({
          username: this.readUserObject(e.user.user),
          role: this.changeRoleView(e.user.role),
          date: e.dataExprimariiVotului ? this.toDateAndTime(e.dataExprimariiVotului) : '',
          vote:
            {
              aprob: 'Aprobat',
              resping: 'Respins',
              'ma abtin': 'S-a abținut',
            }[e.optiuneaVotului] || '',
        }))
      )
    },
    setPopupEscPreventState(val) {
      window.requestAnimationFrame(() => {
        window._PreventEscCloseForPopup = !! val;
      })
    },
    eventListener(ev) {
      if (ev?.key === "Escape") {
        this.setModal();
      }
    }
  },
  watch: {
    detailesModal(x) {
      this.setPopupEscPreventState(x)
    }
  },
  beforeDestroy() {
    this.setPopupEscPreventState()
    document.removeEventListener('keyup', this.eventListener)
  },
  created() {
    this.syncStatistics()

    document.addEventListener('keyup', this.eventListener)
  },
}
</script>

<style src="@/assets/PaapVoteStatistics.css"></style>
