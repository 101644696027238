<template>
  <div>
    <Teleport to="#nvqoiokqndapo">
      <button
        class="btn btn-default"
        :class="{ disabled: !isCompleted && !isChanged }"
        type="reset"
        @click="clearFilterData"
      >
        <i class="btn-label fa fa-trash-o"></i>
        STERGE FILTRE
      </button>
      <button
        class="btn btn-entity"
        :class="{ disabled: !isChanged }"
        type="submit"
        style="margin-left: 1rem"
        @click="filterData"
      >
        <i class="btn-label fa fa-filter"></i>
        FILTREAZA
      </button>
    </Teleport>

    <div class="widget-body" style="min-height: 50px;">
      <div>
        <div class="ng-scope">
          <div class="row">
            <div class="col-md-4 margin-bottom-10">
              <label class="form-label">Obiectul contractului</label>
              <input
                v-model="filteringValues.obiectulContractului"
                type="text"
                class="vInput"
              />
            </div>
            <div class="col-md-4 margin-bottom-10">
              <label class="form-label">Responsabil achizitie</label>
              <input
                v-model="filteringValues.responsabilAchizitie"
                type="text"
                class="vInput"
              />
            </div>
            <div class="col-md-4 margin-bottom-10">
              <label class="form-label"
                >Cod sau denumire CPV
                <i
                  class="fas fa-info-circle"
                  v-tooltip="'Cod sau denumire CPV (principal sau secundar)'"
                ></i
              ></label>
              <v-select
                label="cpvCode"
                :options="filteringData.cpvCodes.options"
                :appendToBody="true"
                :multiple="true"
                v-model="filteringValues.codCpv"
              >
                <template #option="{ cpvCode }">
                  <span class="v-select_li_custom">
                    <span>{{ cpvCode }}</span>
                    <i v-tooltip="cpvCode" class="fas fa-info-circle"></i>
                  </span>
                </template>
              </v-select>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 margin-bottom-10">
              <label class="form-label">Tip contract</label>
              <v-select
                :options="filteringData.contractType.options"
                :searchable="false"
                :appendToBody="true"
                v-model="filteringValues.tipContract"
              >
                <template #option="{ label }">
                  <span class="v-select_li_custom">
                    <span>{{ label }}</span>
                    <i v-tooltip="label" class="fas fa-info-circle"></i>
                  </span>
                </template>
              </v-select>
            </div>
            <div class="col-md-4 margin-bottom-10">
              <label class="form-label">Tip procedura</label>
              <v-select
                label="achizitionModality"
                :options="filteringData.procedureType.options"
                :appendToBody="true"
                v-model="filteringValues.tipProcedura"
              >
                <template #option="{ achizitionModality }">
                  <span class="v-select_li_custom">
                    <span>{{ achizitionModality }}</span>
                    <i v-tooltip="achizitionModality" class="fas fa-info-circle"></i>
                  </span>
                </template>
              </v-select>
            </div>
            <div class="col-md-4 margin-bottom-10">
              <label class="form-label">Modalitatea de finantare</label>
              <v-select
                label="sourceName"
                :options="filteringData.financingMethod.options"
                :appendToBody="true"
                v-model="filteringValues.modalitateaDeFinantare"
              >
                <template #option="{ sourceName }">
                  <span class="v-select_li_custom">
                    <span>{{ sourceName }}</span>
                    <i v-tooltip="sourceName" class="fas fa-info-circle"></i>
                  </span>
                </template>
              </v-select>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 margin-bottom-10 mx-full_width">
              <label class="form-label">Data estimata de incepere</label>
              <date-picker range v-model="filteringValues.dataEstimata" />
            </div>
            <div class="col-md-4 margin-bottom-10">
              <label class="form-label"
                >Valoarea estimata RON
                <i
                  class="fas fa-info-circle"
                  v-tooltip="'Valoarea estimata RON (cu TVA)'"
                ></i
              ></label>
              <div class="row line-between">
                <div class="col-xs-6">
                  <inp-number
                    v-model="filteringValues.valoareaEstimata[0]"
                    placeholder="De la"
                  />
                </div>
                <div class="col-xs-6">
                  <inp-number
                    v-model="filteringValues.valoareaEstimata[1]"
                    placeholder="Până la"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-4 margin-bottom-10">
              <label class="form-label">Observații</label>
              <input
                type="text"
                class="vInput"
                v-model="filteringValues.observatii"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/mixins/DatePicker.js'

const filteringData = () => ({
  cpvCodes: {
    options: [],
  },
  contractType: {
    options: [
      {
        label: 'Contract',
        value: 'Contract',
        id: 1,
      },
      {
        label: 'Factură',
        value: 'Factura',
        id: 3,
      },
      {
        label: 'Acord-Cadru',
        value: 'Acord-Cadru',
        id: 2,
      },
    ],
  },
  procedureType: {
    options: [],
  },
  financingMethod: {
    options: [],
  },
})

const filteringScheme = {
  obiectulContractului: {
    path: 'name',
  },
  responsabilAchizitie: {
    prepareFn(reference) {
      return reference?.responsabilAchizitie?.user
        ? this.readUserObject(reference.responsabilAchizitie.user)
        : ''
    },
  },
  codCpv: {
    path: [
      'need.product.cpvCategory',
      'complexIncludedNeeds.PREPARE_MAP.product.cpvCategory',
    ],
    type: 'objId',
    objIdKey: 'cpvCode',
    multiple: true,
  },
  tipContract: {
    path: 'needReferateAchizitionType',
    extractFn(val) {
      return val?.value || ''
    }
  },
  tipProcedura: {
    path: 'registrulPragurilor',
    type: 'objId'
  },
  valoareaEstimata: {
    path: 'sumnr',
    type: 'number',
    range: true,
  },
  dataEstimata: {
    path: 'calendar.signContract',
    type: 'date',
    range: true,
  },
  modalitateaDeFinantare: {
    path: 'sursaDeFinantareSiPozitiaBuget',
    type: 'objId'
  },
  observatii: {
    path: '_observatii'
  },
}

export default {
  mixins: [DatePicker],
  props: {
    paap: {
      type: Object,
      required: true,
      default: () => ({}),
    }
  },
  data() {
    return {
      filteringData: filteringData(),
      filteringValues: this.getFilteringValues(),
      filteringValuesCaptured: null,
    }
  },
  computed: {
    isValid() {
      return Array.isArray(this.paap.needReferate)
    },
    isCompleted() {
      return this.containsValidValues(this.filteringValues)
    },
    isChanged() {
      const x = this.filteringValuesCaptured

      return !(x && x === JSON.stringify(this.filteringValues))
    },
  },
  methods: {
    captureFilterValues() {
      this.filteringValuesCaptured = JSON.stringify(this.filteringValues)
    },
    getFilteringValues() {
      return this.filterManager(filteringScheme, true)
    },
    syncFilterData() {
      if (!this.isValid) return
      const { paap } = this
      const references = Array.isArray(paap.needReferate) ? paap.needReferate : []

      // procedureType
      this.filteringData.procedureType.options = this.removeArrDuplicatesWithkeys(references.map(
        e => e?.registrulPragurilor
      ).filter(e => this.isObject(e)))

      // financingMethod
      this.filteringData.financingMethod.options = this.removeArrDuplicatesWithkeys(references.map(
        e => e?.sursaDeFinantareSiPozitiaBuget
      ).filter(e => this.isObject(e)))

      // cpvCodes
      this.filteringData.cpvCodes.options = this.removeArrDuplicatesWithkeys(
        references.flatMap(reference => [
          reference.need?.product?.cpvCategory,
          ...(Array.isArray(reference.complexIncludedNeeds)
            ? reference.complexIncludedNeeds.flatMap(
                need => need.product?.cpvCategory
              )
            : []),
        ])
      )
    },
    filterData() {
      if (!this.isValid || !this.isChanged) return
      const references = this.paap.needReferate || []
      this.captureFilterValues()

      this.$emit(
        'filter',
        this.isCompleted
          ? this.filterManager(
              filteringScheme,
              this.filteringValues,
              references
            )
          : references
      )
    },
    clearFilterData() {
      if (!this.isCompleted && !this.isChanged) return
      this.filteringValues = this.getFilteringValues()
      this.captureFilterValues()

      this.$emit('filter', this.paap.needReferate)
    },
  },
  created() {
    this.captureFilterValues()
    this.syncFilterData()
  },
}
</script>
