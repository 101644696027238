export default {
  data() {
    return {
      PERMISIONS: {
        list: false,
        create: false,
        edit: false,
        startVote: false,
        cancelVote: false,
        delete: false,
      }
    }
  },
  created() {
    const baseRule = 'paap'

    this.initPermision(`${baseRule}.get`,'list')
    this.initPermision(`${baseRule}.post`,'create')
    this.initPermision(`${baseRule}.patch`,'edit')
    this.initPermision(`${baseRule}.start_vote`,'startVote')
    this.initPermision(`${baseRule}.cancel_vote`,'cancelVote')
    this.initPermision(`${baseRule}.delete`,'delete')
  }
}