<template>
  <div class="VerticalTableParent">
    <template v-if="headers.length && body.length">
      <div v-for="(e,i) in body" :key="i">
        <div class="VerticalTableBody">
          <table>
            <tbody>
              <tr>
                <td>NR</td>
                <td>{{i+1}}.</td>
              </tr>
              <tr v-for="(e,ind) in headers" :key="ind">
                <td>{{e}}</td>
                <td>
                  <template v-if="!isArray(body[i][ind])">
                    {{body[i][ind]}}
                  </template>
                  <div v-else v-for="(e,i) in body[i][ind]" :key="i">
                    <b>{{i+1}}.</b> {{e}}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </div>
</template>

<style src="@/assets/VerticalTable.css"></style>

<script>
  export default {
    props: {
      Data: {
        type: Object,
        required: true,
        default: () => ({})
        }
    },
    computed: {
      headers() {
        return Array.isArray(this.Data?.headers) ? this.Data.headers : []
      },
      body() {
        return Array.isArray(this.Data?.data) ? this.Data.data : []
      }
    },
    methods: {
      isArray(val) {
        return Array.isArray(val)
      }
    }
  }
</script>