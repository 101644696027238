<template>
  <div>
    <div class="PaapReferencePreviewHeader">
      <div>
        <p>Unitate responsabilă:</p>
        <span>{{ header.responsibleUnit }}</span>
      </div>
      <div class="PaapPreviewRoleBox">
        <div>
          <p>{{ header.userName }}</p>
          <p>{{ header.userInst }}</p>
          <p>{{ header.userDep }}</p>
        </div>
        <transition-group name="popup" tag="div">
          <div key="0" v-if="avatarLoading">
            <i class="fas fa-sync fa-spin"></i>
          </div>
          <img
            key="1"
            v-else
            :src="header.userAvatar || userPLaceholder"
            alt="Avatar"
          />
        </transition-group>
      </div>
    </div>
    <div class="PaapReferencePreview" :class="{boxColor}">
      <div>
        <div>
          <div>
            <b>Detalii contract</b>
          </div>
          <div class="paap_box-preview">
            <table>
              <tbody>
                <tr>
                  <td>
                    <span>Tipul contractului:</span>
                  </td>
                  <td>{{ I.tipContract }}</td>
                </tr>
                <tr>
                  <td>
                    <span
                      >CUI
                      <i
                        class="fas fa-info-circle"
                        v-tooltip="'Cod unic de identificare'"
                      ></i
                      >:</span
                    >
                  </td>
                  <td>{{ I.codUnic }}</td>
                </tr>
                <tr>
                  <td>
                    <span>Tipul procedurii:</span>
                  </td>
                  <td>{{ I.tipProcedura }}</td>
                </tr>
                <tr>
                  <td>
                    <span>Obiectul contractului:</span>
                  </td>
                  <td>{{ I.obiectContract }}</td>
                </tr>
                <tr>
                  <td>
                    <span
                      >Desfășurare
                      <i
                        class="fas fa-info-circle"
                        v-tooltip="'Modalitate de desfășurare'"
                      ></i
                      >:</span
                    >
                  </td>
                  <td>{{ I.modalitateDesf }}</td>
                </tr>
                <tr>
                  <td>
                    <span>Modalitatea de finanțare:</span>
                  </td>
                  <td>{{ I.modalitateFinantare }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div>
            <b class="BetweenSpace" style="margin-right: -1rem;">
              <span>Valoare estimativă eur</span>
              <div>
                <div class="PaapViewerExchange">
                  <div
                    @click="showEurExchange = false"
                    :class="{ active: !showEurExchange }"
                  >
                    RON
                  </div>
                  <div
                    @click="showEurExchange = true"
                    :class="{ active: showEurExchange }"
                  >
                    EURO
                  </div>
                </div>
              </div>
            </b>
          </div>
          <div class="paap_box-preview-1">
            <div>
              <span>Fără TVA</span>
              <div style="padding: 1rem;white-space: nowrap;">
                <div v-if="currencyLoading">
                  <i class="fas fa-sync fa-spin"></i>
                </div>
                <span v-else>{{ I.eurFaraTva || I.estimatFaraTva }}</span>
              </div>
            </div>
            <div>
              <span>Cu TVA</span>
              <div style="padding: 1rem;white-space: nowrap;">
                <div v-if="currencyLoading">
                  <i class="fas fa-sync fa-spin"></i>
                </div>
                <span v-else>{{ I.eurCuTva || I.estimatCuTva }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>
            <b>Coduri CPV</b>
          </div>
          <div class="paap_box-preview">
            <table>
              <tbody>
                <tr>
                  <td>
                    <span>Cod CPV principal:</span>
                  </td>
                  <td v-html="I.cpvPrincipal"></td>
                </tr>
                <tr>
                  <td>
                    <span>Coduri CPV secundare:</span>
                  </td>
                  <td class="childMarginBottom">
                    <div v-for="(e, i) in I.cpvSecundar" :key="i" v-html="e"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div>
            <b>Alte informații</b>
          </div>
          <div class="paap_box-preview">
            <table>
              <tbody>
                <tr>
                  <td>
                    <span>Data estimată începere:</span>
                  </td>
                  <td>{{ I.dataIncepere }}</td>
                </tr>
                <tr>
                  <td>
                    <span>Data estimată finalizare:</span>
                  </td>
                  <td>{{ I.dataFinalizare }}</td>
                </tr>
                <tr>
                  <td>
                    <span>Obiectivul vizat:</span>
                  </td>
                  <td class="childMarginBottom">
                    <div v-for="(e, i) in I.obiectVizat" :key="i">{{ e }}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Observații:</span>
                  </td>
                  <td>{{ I.observatii }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@/assets/PaapReferencePreview.css"></style>

<script>
import { ANOTHER_USER_DOWNLOAD, CURRENCY } from '@/api.js'
import FileManager from '@/mixins/fileManager.js'
import { mapGetters, mapMutations } from 'vuex'

import userPLaceholder from '@/assets/media/user_placeholder_default.png'

const defaultData = () => ({
  tipContract: '',
  codUnic: '',
  tipProcedura: '',
  obiectContract: '',
  modalitateDesf: '',
  modalitateFinantare: '',
  estimatCuTva: '0,00',
  estimatFaraTva: '0,00',
  eurCuTva: '',
  eurFaraTva: '',
  cpvPrincipal: '',
  cpvSecundar: [],
  dataIncepere: '',
  dataFinalizare: '',
  obiectVizat: '',
  observatii: '',
})
const headerData = () => ({
  responsibleUnit: '',
  userName: '',
  userInst: '',
  userDep: '',
  userAvatar: '',
})

export default {
  mixins: [FileManager],
  props: {
    paap: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    reference: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    usersAvatarCache: {
      type: Object,
      default: () => ({}),
    },
    boxColor: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['getExchange']),
  },
  data() {
    return {
      I: defaultData(),
      header: headerData(),
      showEurExchange: false,
      currencyLoading: false,
      avatarLoading: false,
      eurExchange: null,
      userPLaceholder,
    }
  },
  watch: {
    showEurExchange: {
      handler(val) {
        this.syncExchange(val)
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(['setExchange']),
    syncExchange(val) {
      const showEur = data => {
        this.I.eurCuTva = data?.eurCuTva || ''
        this.I.eurFaraTva = data?.eurFaraTva || ''
      }
      if (val) {
        if (this.eurExchange) {
          showEur(this.eurExchange)
        } else {
          const saveEur = eur => {
            const estimatCuTva = this.getLocaleNumber(this.I.estimatCuTva)
            const estimatFaraTva = this.getLocaleNumber(this.I.estimatFaraTva)
            if (
              !this.isNumber(estimatCuTva) ||
              !this.isNumber(estimatFaraTva)
            ) {
              error('Error code: 312928')
              return
            }

            this.currencyLoading = false
            this.eurExchange = {
              eurCuTva:
                '€ ' + this.prepareLocaleNumber(estimatCuTva / eur.rate),
              eurFaraTva:
                '€ ' + this.prepareLocaleNumber(estimatFaraTva / eur.rate),
            }
            if (this.showEurExchange) showEur(this.eurExchange)
          }
          const findEur = exchange =>
            exchange?.find(
              e => e && e.currencyCode == 'EUR' && this.isNumber(+e.rate)
            ) || null
          const getEur = () => {
            const error = msg => {
              this.showEurExchange = false
              this.syncExchange(false)
              this.$toastr.e(msg || 'Încărcarea schimbului valutar a eșuat.')
            }
            this.currencyLoading = true
            CURRENCY()
              .then(res => {
                let findCurrency = this.walker(res, ['data', 'result', '0'])
                if (Array.isArray(findCurrency)) {
                  let find = findEur(findCurrency)
                  if (find) {
                    saveEur(find)
                    this.setExchange(findCurrency)
                  } else {
                    error('Valuta ”EUR” nu a fost găsită în schimbul valutar.')
                  }
                } else {
                  error()
                }
              })
              .catch(error)
          }

          if (Array.isArray(this.getExchange)) {
            const find = findEur(this.getExchange)
            if (find) saveEur(find)
            else getEur()
          } else {
            getEur()
          }
        }
      } else {
        showEur()
        this.currencyLoading = false
      }
    },
    syncUserAvatar() {
      const uuid = this.reference.responsabilAchizitie?.user?.uuid
      if (!uuid || !this.isString(uuid)) return
      const cache = this.usersAvatarCache[uuid]

      if (cache === false) {
        this.avatarLoading = false
        return
      }

      if (cache) {
        if (cache === true) {
          setTimeout(this.syncUserAvatar, 1000)
          this.avatarLoading = true
        } else {
          this.header.userAvatar = cache
          this.avatarLoading = false
        }

        return
      }
      const setCache = val => this.$set(this.usersAvatarCache, uuid, val)

      const warning = () => {
        setCache(false)
        this.avatarLoading = false
      }

      setCache(true)
      this.avatarLoading = true
      ANOTHER_USER_DOWNLOAD(uuid)
        .then(async blob => {
          if (!this.invalidBlob(blob)) {
            const prepare = await this.readAsDataUrl(blob)
            if (prepare) {
              setCache(prepare)
              this.header.userAvatar = prepare
            } else {
              warning()
            }
          } else {
            warning()
          }
          this.avatarLoading = false
        })
        .catch(warning)
    },
    syncData() {
      const x = this.reference
      const y = this.I

      this.header.responsibleUnit = x.institution?.name || '-'
      this.header.userName =
        this.readUserObject(x.responsabilAchizitie?.user) || '-'
      this.header.userInst = x.responsabilAchizitie?.institution?.name || ''
      this.header.userDep = x.responsabilAchizitie?.department?.name || ''

      const prepareCpvLabel = need => need?.product?.cpvCategory
        ? `<b>${this.prepareLocaleNumber(need.sumNeed)}</b> - ${need.product.cpvCategory.cpvCode || '(...)'} - ${need.product
            .cpvCategory.name || '(...)'}`
        : ''

      const dominantNeedId = x.need?.dominantNeed?.id ?? null
      const dominantCpvCategory = prepareCpvLabel(x.need)
      const secondaryCpvCategories = Array.isArray(x.complexIncludedNeeds) && Number.isInteger(dominantNeedId)
        ? x.complexIncludedNeeds
            .filter(
              e =>
                e?.product?.cpvCategory &&
                e.id !== dominantNeedId
            )
            .map(prepareCpvLabel)
        : []

      y.codUnic = x._codUnic
      y.tipProcedura = x.registrulPragurilor?.achizitionModality || ''
      y.cpvPrincipal = dominantCpvCategory || '-'
      y.cpvSecundar = secondaryCpvCategories
      y.dataIncepere = this.toDate(x.calendar?.signContract)
      y.dataFinalizare = this.toDate(x.calendar?.finishContract)
      y.tipContract = x.needReferateAchizitionType
      y.obiectContract = this.textFromHtml(x.necesitateaIdentificata)
      y.modalitateDesf = this.textFromHtml(
        x.justificareaAlegeriiModalitatiiDeSatisfacere
      )
      y.modalitateFinantare =
        x.sursaDeFinantareSiPozitiaBuget?.sourceName || '-'
      y.obiectContract = x.name
      y.observatii = x._observatii

      y.obiectVizat = Array.isArray(x.obiectiveleDinStrategia)
        ? x.obiectiveleDinStrategia.filter(e => this.isString(e) && e.trim())
        : []

      if (this.isNumber(+x.sumnr)) {
        y.estimatCuTva = this.prepareLocaleNumber(x.sumnr)
      }

      y.estimatFaraTva = this.prepareLocaleNumber(
        this._Sum(
          this._Multiply(x.need?.estimatedPriceWoTva, x.need?.qty),
          x.complexIncludedNeeds?.map(e =>
            this._Multiply(e?.estimatedPriceWoTva, e?.qty)
          )
        )
      )
    },
  },
  created() {
    this.syncData()
    this.syncUserAvatar()
  },
}
</script>
