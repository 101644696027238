<template>
  <div>
    <template v-if="voteExpired">
      <span style="font-size: 2.2rem;">
        Procesul de vot a expirat
      </span>
    </template>
    <template v-else-if="!hasVottedSuccess">
      <div v-if="hasVotted">
        <span style="font-size: 2.2rem;">{{ hasVotted._voteOption }} </span>
        <a href="#" @click.prevent="vote(null)">
          <b>Anulează opinia</b>
        </a>
      </div>
      <div v-else-if="show" class="vote-buttons">
        <button type="button" class="btn btn-success" @click="vote('aprob')">
          Aprob
        </button>
        <button type="button" class="btn btn-danger" @click="vote('resping')">
          Resping
        </button>
        <button type="button" class="btn btn-dark" @click="vote('ma abtin')">
          Mă abțin
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { PAAP_VOTE } from '@/api.js'

export default {
  props: {
    voteData: {
      type: Object,
      default: null,
    },
    closeModal: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      hasVottedSuccess: false,
    }
  },
  computed: {
    canVote() {
      const userRoleId = this.getUserRole(true)

      return (
        this.voteData?.participants?.find(
          e => e.userRole?.id === userRoleId && !e.optiuneaVotului
        ) ?? null
      )
    },
    hasVotted() {
      const userRoleId = this.getUserRole(true)
      const find = this.voteData?.participants?.find(
        e => e.userRole?.id === userRoleId && e.optiuneaVotului
      )

      return find
        ? {
            ...find,
            _voteOption:
              {
                aprob: 'Ați aprobat',
                resping: 'Ați respins',
                'ma abtin': 'V-ați abținut',
              }[find.optiuneaVotului] || '',
          }
        : null
    },
    voteExpired() {
      return new Date() > new Date(this.voteData?.dataLimita)
    },
    show() {
      if (this.hasVottedSuccess) return false

      const userRole = this.getUserRole(false, true)
      if (
        !userRole.isConsilier ||
        !Array.isArray(this.voteData?.participants) ||
        !this.voteData?.dataLimita ||
        this.voteExpired
      ) {
        return false
      }

      if (this.canVote) {
        return true
      }

      return false
    },
  },
  methods: {
    vote(optiuneaVotului) {
      if (!this.show && !this.hasVotted) return

      const error = msg => {
        this.setLoad()
        this.$toastr.e(msg || 'Încercați mai târziu.')
      }

      this.setSafeLoad()
      PAAP_VOTE.setVote(
        this.voteData?.id,
        (this.canVote || this.hasVotted)?.user?.id,
        {
          optiuneaVotului,
        }
      )
        .then(res => {
          if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            this.setLoad()
            this.$toastr.s(optiuneaVotului === null ? 'V-ați anulat opinia cu succes!' : 'Ați votat cu succes!')
            this.hasVottedSuccess = true

            setTimeout(this.closeModal, 500)
          } else {
            error()
          }
        })
        .catch(error)
    },
  },
}
</script>

<style lang="scss">
.vote-buttons {
  > *:not(:last-child) {
    margin-right: 1rem;
  }
}
</style>
