<template>
  <div
    class="CommentsSidebar"
    :class="{ active: isOpened }"
    v-click-outside="closePanel"
  >
    <div>
      <v-loading
        :active="loading"
        spinner="bar-fade-scale"
        background-color="rgba(255,255,255, 0.25)"
      />
      <div class="CS_header">
        <div>
          Comentarii 
          <transition name="popup">
            <span v-if="totalLength">({{ totalLength }})</span>
          </transition>
        </div>
        <div>
          <span @click="closePanel">
            <i class="fas fa-times"></i>
          </span>
        </div>
      </div>
      <div
        class="CS_box"
        v-infinite-scroll="loadMore"
        infinite-scroll-distance="700"
        infinite-scroll-immediate-check="false"
      >
        <transition-group name="popup" tag="div">
          <div
            key="a"
            v-if="!localLength && !loading && !apiLoading && validId"
            style="text-align: center;position: absolute;top: 0;left: 0;right: 0;"
          >
            <h4
              class="prettyH4"
              style="color: #888"
            >
              <i class="fas fa-times"></i>
              Nu sunt comentarii
            </h4>
          </div>
          <div v-else v-for="(e, i) in comments" :key="i">
            <p>
              <span>{{ e.author }}</span> ● <span>{{ e.createdAt }}</span>
            </p>
            <div style="display: flex;justify-content: space-between;align-items: flex-end;">
              {{ e.comment }}
              <span v-if="e.haveFiles" @click="viewFiles(i)" style="cursor: pointer;">
                <i class="fas fa-file"></i>
              </span>
            </div>
          </div>
          <div key="b" v-if="canLoadMore">
            <a @click.prevent="loadMore(true)" href="#">Mai încarcă</a>
          </div>
        </transition-group>
      </div>
      <div style="margin: 0 1rem 1rem;">
        <a
          @click.prevent="addComment"
          href="#"
          style="font-size: 2rem;"
        >
          Scrie un comentariu
          <i class="fas fa-plus"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { PAAP } from '@/api.js'
import DialogModal from '@/components/DialogModal'
import { mapMutations } from 'vuex'

const safeApi = window['apiTimeout'](400, false, false, true)

export default {
  props: {
    value: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
    paapId: {
      type: Number,
      required: true,
      default: 0,
    },
    referenceId: {
      type: Number,
      required: true,
      default: 0,
    },
    getDefaultAuthorName: {
      type: Function,
      default: () => ''
    }
  },
  data() {
    return {
      isOpened: false,
      loading: false,
      apiLoading: false,
      disabledOutsideClose: false,
      loadedPage: 1,
      recordsPerPage: 25,
      recordsQuantity: 0,
      comments: [],
    }
  },
  computed: {
    validId() {
      const id = this.value
      return Number.isInteger(id) && id > 0
    },
    safeId() {
      return this.validId ? this.value : null
    },
    localLength() {
      return this.comments.length
    },
    totalLength() {
      return Math.max(this.recordsQuantity, this.localLength)
    },
    canLoadMore() {
      return this.recordsQuantity > this.localLength
    },
  },
  watch: {
    safeId: {
      handler() {
        this.isOpened = this.validId
        this.reset(true)

        if (this.validId) {
          this.syncComments(true)
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(['addWindow']),
    closePanel() {
      if (this.disabledOutsideClose) return
      this.$emit('input', false)
    },
    reset(doNotSync) {
      this.disabledOutsideClose = false
      this.apiLoading = false
      this.comments.splice(0)
      this.loadedPage = 1
      this.recordsQuantity = 0

      if (!doNotSync) this.syncComments(true)
    },
    escapeDetectListener(x) {
      if (this.isOpened && x.key === 'Escape') {
        this.closePanel()
      }
    },
    removeListener() {
      document.removeEventListener('keydown', this.escapeDetectListener)
    },
    setListener() {
      document.addEventListener('keydown', this.escapeDetectListener)
    },
    viewFiles(index) {
      const find = this.comments[index]
      if(!find?.attachedFiles) return

      this.addWindow({
        fileList: find.attachedFiles,
        itemId: find.id,
        searchModuleApi: (mid, filename) => PAAP.download(mid, this.value, this.referenceId, filename),
        canDelete: false,
        modal: 13,
      });
    },
    readApiData(data) {
      if (!Array.isArray(data)) return []

      return data
        .map(e => ({
          author: e.author || '-',
          comment: e.message || '-',
          haveFiles: Array.isArray(e.attachedFiles) && e.attachedFiles.length,
          attachedFiles: e.attachedFiles || [],
          createdAt: e.createdAt ? this.toDateAndTime(e.createdAt) : '-',
          id: e.id ?? this.makeid(6),
          isValid: !!e.id,
        }))
        .filter(e => e.isValid)
    },
    syncComments(forced, showLoad) {
      if (
        !Number.isInteger(this.safeId) ||
        !this.validId ||
        this.apiLoading ||
        (!forced && !this.canLoadMore)
      )
        return

      const error = msg => {
        this.$toastr.e(msg || 'Mesajele nu au fost încărcate.')
        this.loading = false
        this.apiLoading = false
        this.closePanel()
      }
      this.apiLoading = true

      safeApi(() => {
        if (forced || showLoad) this.loading = true
        PAAP.getComments(this.safeId, this.loadedPage, this.recordsPerPage)
          .then(res => {
            if (Number.isInteger(res?.data?.recordsQuantity)) {
              this.recordsQuantity = res.data.recordsQuantity
            }
            if (Array.isArray(res?.data?.result)) {
              if (res.data.result.length) {
                this.comments.push(...this.readApiData(res.data.result))
                this.loadedPage++
              }
  
              this.loading = false
              this.apiLoading = false
            } else {
              error()
            }
          })
          .catch(error)
      })
    },
    loadMore(showLoad) {
      this.syncComments(false, showLoad)
    },
    addComment() {
      this.disabledOutsideClose = true
      this.$modal.show(
        DialogModal,
        {
          target: 'AddComment',
          title: 'Adaugă comentariu',
          inputs: [
            {
              label: 'Nume autor',
              id: 'author',
              value: this.getDefaultAuthorName(),
              errReason: 'Introduceți numele autorului.',
            },
            {
              label: 'Comentariu',
              id: 'message',
              type: 'textarea',
              errReason: 'Scrieți comentariul.',
              checker: e => !!this.textFromHtml(e).trim(),
            },
            ...(this.isAuthorized ? [{
              id: 'files',
              type: 'files',
              componentProps: {
                allowExtensions: ['jpeg','jpg','png','bmp','doc','docx','xls','xlsx','txt','pdf','ppt','tiff']
              },
              checker: () => true
            }] : [])
          ],
          closeBtnText: 'Anulează',
          button: {
            type: 2,
            value: 'Adaugă',
            handler: ({ message, author, files }) => {
              const error = msg => {
                this.$toastr.e(msg || 'Crearea comentariului a eșuat.')
                this.setLoad()
              }

              const formData = new FormData()
              formData.append('paap', this.paapId)
              formData.append('topic', this.safeId)
              formData.append('referate', this.referenceId)
              formData.append('author', author)
              formData.append('message', message)
              for(let file of files) {
                formData.append('files', file.file)
              }

              this.setSafeLoad(12000)
              PAAP.addComment(formData)
                .then(res => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s('Comentariul a fost creat cu succes.', 'Succces')
                    this.reset()
                    this.setLoad()
                  } else {
                    error()
                  }
                })
                .catch(error)
            },
          },
        },
        {
          name: 'AddComment',
          adaptive: true,
          width: '650',
          height: '800',
        },
        {
          closed: () => {
            this.disabledOutsideClose = false
          },
        }
      )
    },
  },
  mounted() {
    this.setListener()
  },
  beforeDestroy() {
    this.removeListener()
  },
}
</script>
